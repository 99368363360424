import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { createCategory } from "../../Redux/Actions/CategoryActions"

const CreateCategory = () => {
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState<string | unknown>(null);

  const imagebase64 = async (file) => {
    const img = document.createElement("img");
    const canvas = document.createElement("canvas");
    if (canvas instanceof HTMLCanvasElement) {
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        throw new Error('2D Context not supported');
      }
      if (ctx instanceof CanvasRenderingContext2D) {
        const dataUrl = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            img.onload = () => {
              // Reducir el tamaño de la imagen
              let { width, height } = img;
              if (width > height) {
                if (width > 1000) {
                  height *= 1000 / width;
                  width = 1000;
                }
              } else {
                if (height > 1000) {
                  width *= 1000 / height;
                  height = 1000;
                }
              }
              canvas.width = width;
              canvas.height = height;

              // Dibujar la imagen en el canvas
              ctx.drawImage(img, 0, 0, width, height);

              // Obtener la data URL en formato WEBP
              resolve(canvas.toDataURL('image/webp'));
            };
            img.src = event.target.result;
          };
          reader.readAsDataURL(file);
        });

        return dataUrl;
      }
    }
  };

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createCategory(type, name, description, image) as any);
  };

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const image = await imagebase64(file);
      setImage(image);
    }
  };

  return (
    <div className="col-md-12 col-lg-4">
      <form onSubmit={submitHandler}>
        <div className="mb-4">
          <label htmlFor="product_type" className="form-label">
            Nombre
          </label>
          <select className="form-select py-3" id="product_type" value={type} onChange={(e) => setType(e.target.value)}>
            <option value="Producto">Producto</option>
            <option value="Liga">Liga</option>
          </select>
        </div>
        <div className="mb-4">
          <label htmlFor="product_name" className="form-label">
            Nombre
          </label>
          <input
            type="text"
            placeholder="Type here"
            className="form-control py-3"
            id="product_name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="form-label">Imagen</label>
          <input className="form-control" type="file" accept="image/*" onChange={handleUploadImage} />
        </div>
        <div className="mb-4">
          <label className="form-label">Descripción</label>
          <textarea
            placeholder="Type here"
            className="form-control"
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>

        <div className="d-grid">
          <button type="submit" className="btn btn-primary py-3">Crear categoría</button>
        </div>
      </form>
    </div>
  );
};

export default CreateCategory;