import React from "react";

const OrderDetailInfo = (props) => {
  const { order } = props;
  return (
		<div className="row mb-5 order-info-wrap">
			<div className="col-md-6 col-lg-4">
				<article className="icontext align-items-start">
					<span className="icon icon-sm rounded-circle alert-success">
						<i className="text-success fas fa-user"></i>
					</span>
					<div className="text">
						<h6 className="mb-1">Cliente</h6>
						<p className="mb-1">{order.user.name}</p>
						<p className="mb-1">
							<a href={`mailto:${order.user.email}`}>{order.user.email}</a>
						</p>
						<p className="mb-1">{order.user.phone}</p>
					</div>
				</article>
			</div>
			<div className="col-md-6 col-lg-4">
				<article className="icontext align-items-start">
					<span className="icon icon-sm rounded-circle alert-success">
						<i className="text-success fas fa-truck-moving"></i>
					</span>
					<div className="text">
						<h6 className="mb-1">Información del pedido</h6>
						<p className="mb-1">
							Envío: {order.shippingAddress.country} <br /> Método de pago: {order.paymentMethod}
						</p>
					</div>
				</article>
			</div>
			<div className="col-md-6 col-lg-4">
				<article className="icontext align-items-start">
					<span className="icon icon-sm rounded-circle alert-success">
						<i className="text-success fas fa-map-marker-alt"></i>
					</span>
					<div className="text">
						<h6 className="mb-1">Entregar a</h6>
						<p className="mb-1">
							Dirección: {order.shippingAddress.city}
							<br />
							{order.shippingAddress.address}
							<br /> {order.shippingAddress.postalCode}
						</p>
					</div>
				</article>
			</div>
		</div>
	);
};

export default OrderDetailInfo;
