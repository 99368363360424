import React from "react";
import { Link, NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
		<div>
			<button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">
				<i className="md-28 fas fa-bars"></i>
			</button>

			<div className="offcanvas offcanvas-start" data-bs-scroll="true" tabIndex={-1} id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
				<div className="offcanvas-header justify-content-between">
					<Link to="/home" className="brand-wrap">
						<img src="/logo.webp" width={92} className="logo" alt="Ecommerce dashboard template" />
					</Link>
					<button type="button" className="btn btn-aside-minimize" data-bs-dismiss="offcanvas" aria-label="Close">
						<i className="text-muted fas fa-stream"></i>
					</button>
				</div>
				<div className="offcanvas-body">
					<ul className="menu-aside">
						<li className="mb-2">
							<NavLink className="menu-link" to="/home">
								<i className="icon fas fa-home"></i>
								<span className="text">Panel</span>
							</NavLink>
						</li>
						<li className="mb-2">
							<NavLink className="menu-link" to="/products">
								<i className="icon fas fa-shopping-bag"></i>
								<span className="text">Productos</span>
							</NavLink>
						</li>
						<li className="mb-2">
							<NavLink className="menu-link" to="/addproduct">
								<i className="icon fas fa-cart-plus"></i>
								<span className="text">Añadir Producto</span>
							</NavLink>
						</li>
						<li className="mb-2">
							<NavLink className="menu-link" to="/category">
								<i className="icon fas fa-list"></i>
								<span className="text">Categorías</span>
							</NavLink>
						</li>
						<li className="mb-2">
							<NavLink className="menu-link" to="/orders">
								<i className="icon fas fa-bags-shopping"></i>
								<span className="text">Pedidos</span>
							</NavLink>
						</li>
						<li className="mb-2">
							<NavLink className="menu-link" to="/users">
								<i className="icon fas fa-user"></i>
								<span className="text">Usuarios</span>
							</NavLink>
						</li>
						<li className="mb-2">
							<NavLink className="menu-link" to="/custom-page">
								<i className="icon fas fa-pager"></i>
								<span className="text">Custom Page</span>
							</NavLink>
						</li>
						<li className="mb-2">
							<NavLink className="menu-link" to="/transaction">
								<i className="icon fas fa-usd-circle"></i>
								<span className="text">Transacciones</span>
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
