import React from "react";

const SaleStatistics = () => {
  return (
		<div className="col-xl-6 col-lg-12">
			<div className="card mb-4 shadow-sm">
				<article className="card-body">
					<h5 className="card-title">Estadísticas de venta</h5>
					<iframe title="Estadísticas de Ventas" className="w-100" width="640" height="480" src="https://charts.mongodb.com/charts-proyectos-myfbw/embed/charts?id=668c1198-9e24-4b2c-87cf-6b5109eae61e&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
				</article>
			</div>
		</div>
	);
};

export default SaleStatistics;
