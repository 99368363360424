import React, { ReactNode } from 'react';

interface MessageProps {
	variant?: string;
	children: ReactNode;
}

const Message: React.FC<MessageProps> = ({ variant = 'alert-info', children }) => {
	return (
		<div className="d-flex justify-content-center col-12">
			<div className={`alert ${variant}`}>{children}</div>
		</div>
	);
};

export default Message;
