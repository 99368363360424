import React from 'react';
import Header from '../components/Header';
import OrderMain from '../components/Orders/OrderMain';

const OrderScreen = () => {
	return (
		<>
			<Header />
			<OrderMain />
		</>
	);
};

export default OrderScreen;
