import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Product from './Product';
import { useDispatch, useSelector } from 'react-redux';
import { listProducts } from '../../Redux/Actions/ProductActions';
import Loading from '../LoadingError/Loading';
import Message from '../LoadingError/Error';

const MainProducts = () => {
	const dispatch = useDispatch();

	const productList = useSelector((state: any) => state.productList);
	const { loading, error, products } = productList;

	const productDelete = useSelector((state: any) => state.productDelete);
	const { error: errorDelete, success: successDelete } = productDelete;

	useEffect(() => {
		dispatch(listProducts() as any);
	}, [dispatch, successDelete]);

	return (
		<section className="content-main">
			<div className="content-header">
				<h2 className="content-title">Productos</h2>
				<div>
					<Link to="/addproduct" className="btn btn-primary">
						Crear nuevo
					</Link>
				</div>
			</div>

			<div className="d-flex row gap-2 border border-1 rounded mb-4 shadow-sm">
				<header>
					<div className="row gx-3 py-3">
						<div className="col-lg-4 col-md-6 me-auto ">
							<input type="search" placeholder="Buscar..." className="form-control p-2" />
						</div>
						<div className="col-lg-2 col-6 col-md-3">
							<select className="form-select">
								<option>Toda la categoría</option>
								<option>Electrónica</option>
								<option>Ropa</option>
								<option>Algo más</option>
							</select>
						</div>
						<div className="col-lg-2 col-6 col-md-3">
							<select className="form-select">
								<option>Últimas añadidas</option>
								<option>Precio más bajo</option>
								<option>Mas visto</option>
							</select>
						</div>
					</div>
				</header>

				<div className="px-2">
					{errorDelete && <Message variant="alert-danger">{errorDelete}</Message>}
					{loading ? (
						<Loading />
					) : error ? (
						<Message variant="alert-danger">{error}</Message>
					) : (
						<div className="row">
							{/* Products */}
							{products.map((product) => (
								<Product product={product} key={product._id} />
							))}
						</div>
					)}
					<nav className="float-end mt-4" aria-label="Page navigation">
						<ul className="pagination">
							<li className="page-item disabled">
								<Link className="page-link" to="#">
									Previo
								</Link>
							</li>
							<li className="page-item active">
								<Link className="page-link" to="#">
									1
								</Link>
							</li>
							<li className="page-item">
								<Link className="page-link" to="#">
									2
								</Link>
							</li>
							<li className="page-item">
								<Link className="page-link" to="#">
									3
								</Link>
							</li>
							<li className="page-item">
								<Link className="page-link" to="#">
									Siguiente
								</Link>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</section>
	);
};

export default MainProducts;
