import React from 'react';
import Header from '../components/Header';
import MainProducts from '../components/Products/MainProducts';

const ProductScreen = () => {
	return (
		<>
			<Header />
			<MainProducts />
		</>
	);
};

export default ProductScreen;
