import { configureStore } from '@reduxjs/toolkit';
import { thunk } from "redux-thunk";
import { userListReducer, userLoginReducer } from "./Reducers/UserReducers";
import {
  productCreateReducer,
  productDeleteReducer,
  productEditReducer,
  productListReducer,
  productUpdateReducer,
} from "./Reducers/ProductReducers";
import { categoryCreateReducer, categoryDeleteReducer, categoryEditReducer, categoryListReducer, categoryUpdateReducer } from "./Reducers/CategoryReducers";
import { orderDeliveredReducer, orderDetailsReducer, orderListReducer } from "./Reducers/OrderReducers";
import { bannerEditReducer, bannerUpdateReducer, cardEditReducer, cardListReducer, cardUpdateReducer } from './Reducers/CustomPageReducers';

const reducer: any = {
	userLogin: userLoginReducer,
	userList: userListReducer,
	productList: productListReducer,
	productDelete: productDeleteReducer,
	productCreate: productCreateReducer,
	productEdit: productEditReducer,
	productUpdate: productUpdateReducer,
	bannerEdit: bannerEditReducer,
	bannerUpdate: bannerUpdateReducer,
	cardList: cardListReducer,
	cardEdit: cardEditReducer,
	cardUpdate: cardUpdateReducer,
	categoryList: categoryListReducer,
	categoryCreate: categoryCreateReducer,
	categoryDelete: categoryDeleteReducer,
	categoryEdit: categoryEditReducer,
	categoryUpdate: categoryUpdateReducer,
	orderList: orderListReducer,
	orderDetails: orderDetailsReducer,
	orderDeliver: orderDeliveredReducer
};

//LOGIN
const userInfoFromLocalStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo") as string)
  : null;

const preloadedState = {
	userLogin: { userInfo: userInfoFromLocalStorage }
};

const store = configureStore({
	reducer,
	preloadedState,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

export default store;