import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateCategory from './CreateCategory';
import CategoriesTable from './CategoriesTable';
import { listCategories } from '../../Redux/Actions/CategoryActions';
import Loading from '../LoadingError/Loading';
import Message from '../LoadingError/Error';

const MainCategories = () => {
	const dispatch = useDispatch();
	const categoryList = useSelector((state: any) => state.categoryList);
	const { loading, error, categories } = categoryList;

	const categoryDelete = useSelector((state: any) => state.categoryDelete);
	const { error: errorDelete, success: successDelete } = categoryDelete;

	useEffect(() => {
		dispatch(listCategories() as any);
	}, [dispatch, successDelete]);
	return (
		<section className="content-main">
			<div className="content-header">
				<h2 className="content-title">Categorias</h2>
			</div>

			<div className="card shadow-sm">
				<div className="card-body">
					<div className="row">
						{/* Create category */}
						<CreateCategory />
						{/* Categories table */}
						{errorDelete && <Message variant="alert-danger">{errorDelete}</Message>}
						{loading ? <Loading /> : error ? <Message variant="alert-danger">{error}</Message> : <CategoriesTable categories={categories} />}
					</div>
				</div>
			</div>
		</section>
	);
};

export default MainCategories;
