import React, { useEffect, useState } from 'react';
import Toast from '../LoadingError/Toast';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { editProduct, updateProduct } from '../../Redux/Actions/ProductActions';
import { PRODUCT_UPDATE_RESET } from '../../Redux/Constants/ProductConstants';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';
import { toast } from 'react-toastify';
import { imagebase64 } from '../../Util/imagebase64';
import { listCategories } from '../../Redux/Actions/CategoryActions';

const ToastObjects = {
	pauseOnFocusLoss: false,
	draggable: false,
	pauseOnHover: false,
	autoClose: 2000
};
const EditProductMain = (props) => {
	const { productId } = props;
	const [type, setType] = useState('Ropa');
	const [liga, setLiga] = useState('LaLiga');
	const [name, setName] = useState('');
	const [price, setPrice] = useState(0);
	const [priceTag, setPriceTag] = useState('');
	const [priceDorsal, setPriceDorsal] = useState('');
	const [image, setImage] = useState<string | unknown>(null);
	const [countInStock, setCountInStock] = useState(0);
	const [sizes, setSize] = useState<string[]>([]);
	const [sales, setSales] = useState(0);
	const [inputSize, setInputSize] = useState('');
	const [description, setDescription] = useState('');

	const dispatch = useDispatch();
	
	const categoryList = useSelector((state: any) => state.categoryList);
	const { loading: loadingCategory, error: errorCategory, categories } = categoryList;

	const productEdit = useSelector((state: any) => state.productEdit);
	const { loading, error, product } = productEdit;

	const productUpdate = useSelector((state: any) => state.productUpdate);
	const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = productUpdate;

	const addSize = () => {
		if (inputSize) {
			setSize((prevSizes) => [...prevSizes, inputSize]);
			setInputSize('');
		}
	};

	const removeSize = (sizeToRemove: string) => {
		setSize((prevSizes) => prevSizes.filter((size) => size !== sizeToRemove));
	};

	const handleUploadImage = async (e) => {
		const file = e.target.files[0];
		
		if (file) {
			//Si el archivo pesa mas de 1MB
			if (file.size > 1000000) {
				toast.error('El tamaño de la imagen es demasiado grande.', ToastObjects);
				return;
			} else {
				toast.success('Imagen cargada', ToastObjects);
				const image = await imagebase64(file);
				setImage(image);
			}
		}
	};

	useEffect(() => {
		dispatch(listCategories() as any);
		if (successUpdate) {
			dispatch({ type: PRODUCT_UPDATE_RESET });
			toast.success('Producto actualizado', ToastObjects);
		} else {
			if (!product.name || product._id !== productId) {
				dispatch(editProduct(productId) as any);
			} else {
				setType(product.type);
				setLiga(product.liga);
				setName(product.name);
				setDescription(product.description);
				setCountInStock(product.countInStock);
				setSize(product.sizes);
				setImage(product.image);
				setPrice(product.price);
				setPriceTag(product.priceTag);
				setPriceDorsal(product.priceDorsal);
				setSales(product.sales);
			}
		}
	}, [product, dispatch, productId, successUpdate]);

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(
			updateProduct({
				_id: productId,
				type,
				liga,
				name,
				price,
				priceTag,
				priceDorsal,
				description,
				image,
				countInStock,
				sizes,
				sales
			}) as any
		);
	};

	const leagueCategories = categories.filter((category) => category.type === 'Liga');
	return (
		<>
			<Toast />
			<section className="content-main" style={{ maxWidth: '1200px' }}>
				<form onSubmit={submitHandler}>
					<div className="content-header">
						<Link to="/products" className="btn btn-danger text-white">
							Ir a productos
						</Link>
						<h2 className="content-title">Actualizar producto</h2>
						<div>
							<button type="submit" className="btn btn-primary">
								Publica ahora
							</button>
						</div>
					</div>

					<div className="row">
						<div className="card shadow-sm">
							<div className="card-body">
								{errorUpdate && <Message variant="alert-danger">{errorUpdate}</Message>}
								{loadingUpdate && <Loading />}
								{loading ? (
									<Loading />
								) : error ? (
									<Message variant="alert-danger">{error}</Message>
								) : (
									<>
										<div className="mb-4">
											<label className="form-label">Tipo de producto</label>
											<select value={type} className="form-select" onChange={(e) => setType(e.target.value)}>
												<option>Selecciona un opción</option>
												<option>Ropa</option>
												<option>Pack</option>
												<option>CustomItem</option>
											</select>
										</div>
										{type === 'Ropa' && (
											<>
												<div className="mb-4">
													<label className="form-label">Tipo de liga</label>
													<select value={liga} className="form-select" onChange={(e) => setLiga(e.target.value)}>
														{leagueCategories.map((category) => (
															<option key={category._id} value={category.name}>
																{category.name}
															</option>
														))}
													</select>
												</div>
												<div className="mb-4">
													<label htmlFor="product_title" className="form-label">
														Titulo del producto
													</label>
													<input type="text" placeholder="Type here" className="form-control" id="product_title" required value={name} onChange={(e) => setName(e.target.value)} />
												</div>
												<div className="mb-4">
													<label htmlFor="product_price" className="form-label">
														Precio
													</label>
													<input type="number" placeholder="0" className="form-control" id="product_price" required value={price} onChange={(e) => setPrice(Number(e.target.value))} />
												</div>
												<div className="mb-4">
													<label htmlFor="product_price_tag" className="form-label">
														Precio Tag
													</label>
													<input type="number" placeholder="0" className="form-control" id="product_price_tag" required value={priceTag} onChange={(e) => setPriceTag(Number(e.target.value))} />
												</div>
												<div className="mb-4">
													<label htmlFor="product_price_dorsal" className="form-label">
														Precio Dorsal
													</label>
													<input type="number" placeholder="0" className="form-control" id="product_price_dorsal" required value={priceDorsal} onChange={(e) => setPriceDorsal(Number(e.target.value))} />
												</div>
												<div className="mb-4">
													<label htmlFor="product_stock" className="form-label">
														Numero de Stock
													</label>
													<input type="number" placeholder="0" className="form-control" id="product_stock" required value={countInStock} onChange={(e) => setCountInStock(Number(e.target.value))} />
												</div>
												<div className="mb-4">
													<label htmlFor="product_sales" className="form-label">
														Numero de Ventas
													</label>
													<input type="number" placeholder="0" className="form-control" id="product_sales" required value={sales} onChange={(e) => setSales(Number(e.target.value))} />
												</div>
												<div className="d-flex flex-column gap-2">
													<label htmlFor="product_size" className="form-label">
														Tallas
													</label>
													<input type="text" placeholder="Type here" className="form-control" id="product_size" value={inputSize} onChange={(e) => setInputSize(e.target.value)} />
													<button className="btn btn-outline-primary" type="button" onClick={addSize}>
														Agregar talla
													</button>
													<div className="d-flex flex-wrap gap-2">
														{sizes.map((s, index) => (
															<span className="badge rounded-pill text-bg-primary" data-bs-theme="dark" key={index}>
																{s}
																<button type="button" className="white btn-close ms-2" onClick={() => removeSize(s)}></button>
															</span>
														))}
													</div>
												</div>
												<div className="mb-4">
													<label className="form-label">Descripción</label>
													<textarea placeholder="Type here" className="form-control" rows={7} required value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
												</div>
												<div className="mb-4">
													<label className="form-label">Imagen</label>
													<input className="form-control" type="file" accept="image/*" onChange={handleUploadImage} />
												</div>
											</>
										)}
										{type === 'Pack' && (
											<>
												<div className="mb-4">
													<label className="form-label">Tipo de liga</label>
													<select value={liga} className="form-select" onChange={(e) => setLiga(e.target.value)}>
														{leagueCategories.map((category) => (
															<option key={category._id} value={category.name}>
																{category.name}
															</option>
														))}
													</select>
												</div>
												<div className="mb-4">
													<label htmlFor="product_title" className="form-label">
														Titulo del producto
													</label>
													<input type="text" placeholder="Type here" className="form-control" id="product_title" required value={name} onChange={(e) => setName(e.target.value)} />
												</div>
												<div className="mb-4">
													<label htmlFor="product_price" className="form-label">
														Precio
													</label>
													<input type="number" placeholder="0" className="form-control" id="product_price" required value={price} onChange={(e) => setPrice(Number(e.target.value))} />
												</div>
												<div className="mb-4">
													<label htmlFor="product_stock" className="form-label">
														Numero de Stock
													</label>
													<input type="number" placeholder="0" className="form-control" id="product_stock" required value={countInStock} onChange={(e) => setCountInStock(Number(e.target.value))} />
												</div>
												<div className="mb-4">
													<label className="form-label">Descripción</label>
													<textarea placeholder="Type here" className="form-control" rows={7} required value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
												</div>
												<div className="mb-4">
													<label className="form-label">Imagen</label>
													<input className="form-control" type="file" accept="image/*" onChange={handleUploadImage} />
												</div>
											</>
										)}
										{type === 'CustomItem' && (
											<>
												<div className="mb-4">
													<label className="form-label">Tipo de liga</label>
													<select value={liga} className="form-select" onChange={(e) => setLiga(e.target.value)}>
														<option value="CustomItem">CustomItem</option>
													</select>
												</div>
												<div className="mb-4">
													<label htmlFor="product_title" className="form-label">
														Titulo del producto
													</label>
													<input type="text" placeholder="Type here" className="form-control" id="product_title" required value={name} onChange={(e) => setName(e.target.value)} />
												</div>
												<div className="mb-4">
													<label htmlFor="product_price" className="form-label">
														Precio
													</label>
													<input type="number" placeholder="0" className="form-control" id="product_price" required value={price} onChange={(e) => setPrice(Number(e.target.value))} />
												</div>
												<div className="mb-4">
													<label htmlFor="product_price_tag" className="form-label">
														Precio Tag
													</label>
													<input type="number" placeholder="0" className="form-control" id="product_price_tag" required value={priceTag} onChange={(e) => setPriceTag(Number(e.target.value))} />
												</div>
												<div className="mb-4">
													<label htmlFor="product_price_dorsal" className="form-label">
														Precio Dorsal
													</label>
													<input type="number" placeholder="0" className="form-control" id="product_price_dorsal" required value={priceDorsal} onChange={(e) => setPriceDorsal(Number(e.target.value))} />
												</div>
												<div className="mb-4">
													<label htmlFor="product_stock" className="form-label">
														Numero de Stock
													</label>
													<input type="number" placeholder="0" className="form-control" id="product_stock" required value={countInStock} onChange={(e) => setCountInStock(Number(e.target.value))} />
												</div>
												<div className="d-flex flex-column gap-2">
													<label htmlFor="product_size" className="form-label">
														Tallas
													</label>
													<input type="text" placeholder="Type here" className="form-control" id="product_size" value={inputSize} onChange={(e) => setInputSize(e.target.value)} />
													<button className="btn btn-outline-primary" type="button" onClick={addSize}>
														Agregar talla
													</button>
													<div className="d-flex flex-wrap gap-2">
														{sizes.map((s, index) => (
															<span className="badge rounded-pill text-bg-primary" data-bs-theme="dark" key={index}>
																{s}
																<button type="button" className="white btn-close ms-2" onClick={() => removeSize(s)}></button>
															</span>
														))}
													</div>
												</div>
												<div className="mb-4">
													<label className="form-label">Descripción</label>
													<textarea placeholder="Type here" className="form-control" rows={7} required value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
												</div>
												<div className="mb-4">
													<label className="form-label">Imagen</label>
													<input className="form-control" type="file" accept="image/*" onChange={handleUploadImage} />
												</div>
											</>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</form>
			</section>
		</>
	);
};

export default EditProductMain;
