import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./Redux/store";
import App from "./App";

// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);
root.render(
	<Provider store={store} children={store}>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</Provider>
);