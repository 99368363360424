import { BANNER_EDIT_FAIL, BANNER_EDIT_REQUEST, BANNER_EDIT_SUCCESS, BANNER_UPDATE_FAIL, BANNER_UPDATE_REQUEST, BANNER_UPDATE_RESET, BANNER_UPDATE_SUCCESS, BANNER_UPLOAD_FAIL, BANNER_UPLOAD_REQUEST, BANNER_UPLOAD_SUCCESS, CARD_EDIT_FAIL, CARD_EDIT_REQUEST, CARD_EDIT_SUCCESS, CARD_UPDATE_FAIL, CARD_UPDATE_REQUEST, CARD_UPDATE_RESET, CARD_UPDATE_SUCCESS, CARDS_LIST_FAIL, CARDS_LIST_REQUEST, CARDS_LIST_SUCCESS } from '../Constants/CustomPageConstants';

interface Card {
	_id: string;
	name: string;
	image: string;
	url: string;
}

// ALL CATEGORIES
export const cardListReducer = (state: { cards: Card[] } = { cards: [] }, action: any) => {
	switch (action.type) {
		case CARDS_LIST_REQUEST:
			return { loading: true, cards: [] };
		case CARDS_LIST_SUCCESS:
			return { loading: false, cards: action.payload };
		case CARDS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

// EDIT CARD
export const cardEditReducer = (state = { card: {} }, action) => {
	switch (action.type) {
		case CARD_EDIT_REQUEST:
			return { ...state, loading: true };
		case CARD_EDIT_SUCCESS:
			return { loading: false, card: action.payload };
		case CARD_EDIT_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

// UPDATE CARD
export const cardUpdateReducer = (state = { card: {} }, action) => {
	switch (action.type) {
		case CARD_UPDATE_REQUEST:
			return { loading: true };
		case CARD_UPDATE_SUCCESS:
			return { loading: false, success: true, card: action.payload };
		case CARD_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		case CARD_UPDATE_RESET:
			return { card: {} };
		default:
			return state;
	}
};

// EDIT BANNER
export const bannerEditReducer = (state = { banner: {} }, action) => {
	switch (action.type) {
		case BANNER_EDIT_REQUEST:
			return { ...state, loading: true };
		case BANNER_EDIT_SUCCESS:
			return { loading: false, banner: action.payload };
		case BANNER_EDIT_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

// UPDATE BANNER
export const bannerUpdateReducer = (state = { banner: {} }, action) => {
	switch (action.type) {
		case BANNER_UPDATE_REQUEST:
			return { loading: true };
		case BANNER_UPDATE_SUCCESS:
			return { loading: false, success: true, banner: action.payload };
		case BANNER_UPDATE_FAIL:
			return { loading: false, error: action.payload };
		case BANNER_UPDATE_RESET:
			return { banner: {} };
		default:
			return state;
	}
};

export const bannerUploadReducer = (state = {}, action) => {
	switch (action.type) {
		case BANNER_UPLOAD_REQUEST:
			return { loading: true };
		case BANNER_UPLOAD_SUCCESS:
			return { loading: false, imageUrl: action.payload };
		case BANNER_UPLOAD_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};
