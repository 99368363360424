import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const Orders = (props) => {
  const { orders } = props;
  return (
		<table className="table">
			<thead>
				<tr>
					<th scope="col">Nombre</th>
					<th scope="col">Email</th>
					<th scope="col">Total</th>
					<th scope="col">Pagado</th>
					<th scope="col">Fecha</th>
					<th>Estado</th>
					<th scope="col" className="text-end">
						Acción
					</th>
				</tr>
			</thead>
			<tbody>
				{orders.map((order) => (
					<tr key={order._id}>
						<td>
							<b>{order.user.name}</b>
						</td>
						<td>{order.user.email}</td>
						<td>{order.totalPrice} €</td>
						<td>{order.isPaid ? <strong className="alert alert-success border border-success rounded-pill py-1 px-2 fs-7">Pagado el {moment(order.paidAt).format('MMM Do YY')}</strong> : <strong className="alert alert-danger border border-danger rounded-pill py-1 px-2 fs-7">No pagado</strong>}</td>
						<td>{moment(order.createdAt).format('MMM Do YY')}</td>
						<td>{order.isDelivered ? <span className="badge text-bg-success">Enviado</span> : <span className="badge text-bg-dark">No enviado</span>}</td>
						<td className="d-flex justify-content-end align-item-center">
							<Link to={`/order/${order._id}`} className="text-success">
								<i className="fas fa-eye"></i>
							</Link>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default Orders;
