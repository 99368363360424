import React from 'react';
import { useSelector } from 'react-redux';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';
import Orders from './Orders';

const OrderMain = () => {
	const orderList = useSelector((state: any) => state.orderList);
	const { loading, error, orders } = orderList;

	return (
		<section className="content-main">
			<div className="content-header">
				<h2 className="content-title">Pedidos</h2>
			</div>

			<div className="d-flex row gap-2 border border-1 rounded mb-4 shadow-sm">
				<header>
					<div className="row gx-3 py-3">
						<div className="col-lg-4 col-md-6 me-auto">
							<input type="text" placeholder="Buscar..." className="form-control p-2" />
						</div>
						<div className="col-lg-2 col-6 col-md-3">
							<select className="form-select">
								<option>Estado</option>
								<option>Activo</option>
								<option>Desactivado</option>
								<option>Mostrar todo</option>
							</select>
						</div>
						<div className="col-lg-2 col-6 col-md-3">
							<select className="form-select">
								<option>Mostrar 20</option>
								<option>Mostrar 30</option>
								<option>Mostrar 40</option>
							</select>
						</div>
					</div>
				</header>
				<div className="px-2">
					<div className="table-responsive">{loading ? <Loading /> : error ? <Message variant="alert-danger">{error}</Message> : <Orders orders={orders} />}</div>
				</div>
			</div>
		</section>
	);
};

export default OrderMain;
