import React from "react";
import Header from "../components/Header";
import Main from "../components/Home/Main";

const HomeScreen = () => {
  return (
    <>
      <Header />
      <Main />
    </>
  );
};

export default HomeScreen;
