import React, { useEffect } from 'react';
import OrderDetailProducts from './OrderDetailProducts';
import OrderDetailInfo from './OrderDetailInfo';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deliverOrder, getOrderDetails } from '../../Redux/Actions/OrderActions';
import Loading from '../LoadingError/Loading';
import Message from '../LoadingError/Error';
import moment from 'moment';

const OrderDetailmain = (props) => {
	const { orderId } = props;
	const dispatch = useDispatch();

	const orderDetails = useSelector((state: any) => state.orderDetails);
	const { loading, error, order } = orderDetails;

	const orderDeliver = useSelector((state: any) => state.orderDeliver);
	const { loading: loadingDelivered, sucess: successDelivered } = orderDeliver;

	useEffect(() => {
		dispatch(getOrderDetails(orderId) as any);
	}, [dispatch, orderId, successDelivered]);

	const deliverHandler = () => {
		dispatch(deliverOrder(order) as any);
	};

	const handlePrint = () => {
		window.print();
	};

	return (
		<section className="content-main">
			<div className="content-header">
				<Link to="/orders" className="btn btn-dark text-white">
					Volver a Pedidos
				</Link>
			</div>
			{loading ? (
				<Loading />
			) : error ? (
				<Message variant="alert-danger">{error}</Message>
			) : (
				<div className="card">
					<header className="card-header p-3" style={{ backgroundColor: '#4fa607' }}>
						<div className="row align-items-center ">
							<div className="col-lg-6 col-md-6">
								<span>
									<i className="far fa-calendar-alt mx-2"></i>
									<b className="text-white">{moment(order.createdAt).format('llll')}</b>
								</span>
								<br />
								<small className="text-white mx-3 ">Order ID: {order._id}</small>
							</div>
							<div className="col-lg-6 col-md-6 ms-auto d-flex justify-content-end align-items-center">
								<select className="form-select d-inline-block" style={{ maxWidth: '200px' }}>
									<option>Cambiar Estado</option>
									<option>En espera de pago</option>
									<option>Confirmado</option>
									<option>Enviado</option>
									<option>Entregado</option>
								</select>
								<button className="btn btn-success ms-2" onClick={handlePrint}>
									<i className="fas fa-print"></i>
								</button>
							</div>
						</div>
					</header>
					<div className="card-body">
						{/* Order info */}
						<OrderDetailInfo order={order} />

						<div className="row">
							<div className="col-lg-9">
								<div className="table-responsive">
									<OrderDetailProducts order={order} loading={loading} />
								</div>
							</div>
							{/* Payment Info */}
							<div className="col-lg-3">
								<div className="box shadow-sm bg-light">
									{order.isDelivered ? (
										<button className="btn btn-success col-12 text-uppercase">Entregado el ( {moment(order.isDeliveredAt).format('MMM Do YY')})</button>
									) : (
										<>
											{loadingDelivered && <Loading />}
											<button onClick={deliverHandler} className="btn btn-dark col-12 text-uppercase">
												Marcar como entregado
											</button>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</section>
	);
};

export default OrderDetailmain;
