import React from "react";
import { Link } from "react-router-dom";

const OrderDetailProducts = (props) => {
  const { order, loading } = props;

  let itemsPrice = 0;

  if (!loading) {
    // Calculate Price
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    itemsPrice = Number(addDecimals(order.orderItems.reduce((acc, item) => acc + item.priceTag + item.priceDorsal + item.price * item.qty, 0)));
  }

  return (
		<table className="table border table-lg">
			<thead>
				<tr>
					<th style={{ width: '40%' }}>Producto</th>
					<th style={{ width: '10%' }}>Precio unitario</th>
					<th style={{ width: '10%' }}>Cantidad</th>
					<th style={{ width: '20%' }}>Tag</th>
					<th style={{ width: '10%' }}>Dorsal</th>
					<th style={{ width: '10%' }} className="text-end">
						Total
					</th>
				</tr>
			</thead>
			<tbody>
				{order.orderItems.map((item, index) => (
					<tr key={index}>
						<td>
							<Link className="itemside" to="#">
								<div className="left">
									<img src={item.image} alt={item.name} style={{ width: '40px', height: '40px' }} className="img-xs" />
								</div>
								<div className="info">{item.name}</div>
							</Link>
						</td>
						<td>{item.price} €</td>
						<td>{item.qty}</td>
						<td>{item.tag !== 'null' ? item.tag : '-'}</td>
						<td>{item.dorsal !== 'null' ? item.dorsal : '-'}</td>
						<td className="text-end">{item.qty * item.price + item.priceTag + item.priceDorsal} €</td>
					</tr>
				))}

				<tr>
					<td colSpan={6}>
						<article className="float-end">
							<dl className="dlist">
								<dt>Subtotal:</dt> <dd>{itemsPrice} €</dd>
							</dl>
							<dl className="dlist">
								<dt>Costo de envío:</dt> <dd className="text-success">Gratis</dd>
							</dl>
							<dl className="dlist">
								<dt>Impuestos:</dt> <dd>{order.taxPrice} €</dd>
							</dl>
							<dl className="dlist">
								<dt>Total:</dt>
								<dd>
									<b className="h5">{order.totalPrice} €</b>
								</dd>
							</dl>
							<dl className="dlist">
								<dt className="text-muted">Estado:</dt>
								<dd>{order.isPaid ? <span className="badge rounded-pill alert alert-success text-success">Pago hecho</span> : <span className="badge rounded-pill alert alert-danger text-danger">No pagado</span>}</dd>
							</dl>
						</article>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

export default OrderDetailProducts;