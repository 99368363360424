import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import EditProductMain from '../components/Products/EditproductMain';

const ProductEditScreen = () => {
	const { id } = useParams();
	return (
		<>
			<Header />
			<EditProductMain productId={id} />
		</>
	);
};
export default ProductEditScreen;
