import React from "react";
import Header from "../components/Header";
import MainCategories from "../components/Categories/MainCategories";

const CategoriesScreen = () => {
  return (
    <>
      <Header />
      <MainCategories />
    </>
  );
};

export default CategoriesScreen;
