import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from './sidebar';
import { logout } from '../Redux/Actions/userActions';

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [isDarkMode, setIsDarkMode] = useState(false);

	const userLogin = useSelector((state: any) => state.userLogin);
	const { userInfo } = userLogin;

	const toggleTheme = () => {
		setIsDarkMode(!isDarkMode);
		document.body.setAttribute('data-bs-theme', isDarkMode ? 'light' : 'dark');
	};

	const logoutHandler = () => {
		dispatch(logout() as any);
		navigate('/login');
	};

	return (
		<header className="navbar border-bottom">
			<div id="DesktopContainer" className="container gap-2">
				<div className="d-flex gap-2">
					<Sidebar />
					<button className="btn btn-outline-secondary" title="Dark mode" onClick={toggleTheme}>
						<i className="fas fa-moon"></i>
					</button>
				</div>
				<ul className="nav nav-pills gap-2">
					<li className="nav-item">
						<Link className="nav-link" to="#">
							<i className="fas fa-bell"></i>
						</Link>
					</li>
					{userInfo ? (
						<button type="button" className="btn btn-primary" onClick={logoutHandler}>
							{userInfo.name}
						</button>
					) : (
						<>
							<Link to="/login" className="btn btn-primary">
								Iniciar sesión
							</Link>
						</>
					)}
				</ul>
			</div>
		</header>
	);
};

export default Header;
