import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import "./App.css";
import "./App.scss";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/productScreen";
import CategoriesScreen from "./screens/CategoriesScreen";
import OrderScreen from "./screens/OrderScreen";
import OrderDetailScreen from "./screens/OrderDetailScreen";
import AddProduct from "./screens/AddProduct";
import Login from "./screens/LoginScreen";
import UsersScreen from "./screens/UsersScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import NotFound from "./screens/NotFound";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "./Redux/Actions/ProductActions";
import { listOrders } from "./Redux/Actions/OrderActions";
import CustomPageScreen from "./screens/CustomPageScreen";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route>
			<Route path="/" Component={Login} />
			<Route path="/login" Component={Login} />
			<Route path="/home" Component={HomeScreen} />
			<Route path="/products" Component={ProductScreen} />
			<Route path="/category" Component={CategoriesScreen} />
			<Route path="/orders" Component={OrderScreen} />
			<Route path="/order/:id" Component={OrderDetailScreen} />
			<Route path="/addproduct" Component={AddProduct} />
			<Route path="/users" Component={UsersScreen} />
			<Route path="/custom-page" Component={CustomPageScreen} />
			<Route path="/product/:id/edit" Component={ProductEditScreen} />
			<Route path="*" Component={NotFound} />
		</Route>
	)
);

const App = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state: any) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listProducts() as any);
      dispatch(listOrders() as any);
    }
  }, [dispatch, userInfo]);
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
