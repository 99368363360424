import React from 'react';
import TopTotal from './TopTotal';
import LatestOrder from './LatestOrder';
import SaleStatistics from './SalesStatistics';
import ProductsStatistics from './ProductsStatistics';
import { useSelector } from 'react-redux';

const Main = () => {
	const orderList = useSelector((state: any) => state.orderList);
	const { loading, error, orders } = orderList;
	const productList = useSelector((state: any) => state.productList);
	const { products } = productList;
	return (
		<>
			<section className="content-main">
				<div className="content-header">
					<h2 className="content-title">Panel</h2>
				</div>
				<TopTotal orders={orders} products={products} />

				<div className="row">
					{/* STATICS */}
					<SaleStatistics />
					<ProductsStatistics />
				</div>

				{/* LATEST ORDER */}
				<div className="card mb-4 shadow-sm">
					<LatestOrder orders={orders} loading={loading} error={error} />
				</div>
			</section>
		</>
	);
};

export default Main;
