import React from "react";

const ProductsStatistics = () => {
  return (
		<div className="col-xl-6 col-lg-12">
			<div className="card mb-4 shadow-sm">
				<article className="card-body">
					<h5 className="card-title">Estadísticas de productos</h5>
					<iframe title="Estadísticas de Productos" className="w-100" width="640" height="480" src="https://charts.mongodb.com/charts-proyectos-myfbw/embed/charts?id=668c1480-9f55-49fd-89da-1a015a2faf96&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
				</article>
			</div>
		</div>
	);
};

export default ProductsStatistics;
