import React from "react";
import Header from "../components/Header";
import CustomPageComponent from "../components/CustomPage/CustomPageComponent";

const CustomPageScreen = () => {
  return (
		<>
			<Header />
			<CustomPageComponent />
		</>
	);
};

export default CustomPageScreen;