export const imagebase64 = async (file) => {
	const maxSize = 1 * 1024 * 1024; // 1MB
	if (file.size > maxSize) {
		throw new Error('El archivo es demasiado grande. Debe ser menor de 1MB.');
	}
	const img = document.createElement('img');
	const canvas = document.createElement('canvas');
	if (canvas instanceof HTMLCanvasElement) {
		const ctx = canvas.getContext('2d');
		if (!ctx) {
			throw new Error('2D Context not supported');
		}
		if (ctx instanceof CanvasRenderingContext2D) {
			const dataUrl = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.onload = (event: any) => {
					img.onload = () => {
						// Reducir el tamaño de la imagen
						let { width, height } = img;
						/*
						if (width > height) {
							if (width > 1000) {
								height *= 1000 / width;
								width = 1000;
							}
						} else {
							if (height > 1000) {
								width *= 1000 / height;
								height = 1000;
							}
						}
						*/
						canvas.width = width;
						canvas.height = height;

						// Dibujar la imagen en el canvas
						ctx.drawImage(img, 0, 0, width, height);

						// Obtener la data URL en formato WEBP
						resolve(canvas.toDataURL('image/webp'));

						// Recortar la data URL para poder enviarla a la API
						
					};
					img.src = event.target.result;
				};
				reader.readAsDataURL(file);
			});

			return dataUrl;
		}
	}
};