import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';

const LatestOrder = (props) => {
	const { loading, error, orders } = props;
	return (
		<div className="card-body">
			<h4 className="card-title">Nuevos pedidos</h4>
			{loading ? (
				<Loading />
			) : error ? (
				<Message variant="alert-danger">{error}</Message>
			) : (
				<div className="table-responsive">
					<table className="table">
						<tbody>
							{orders.slice(0, 5).map((order) => (
								<tr key={order._id}>
									<td>
										<b>{order.user.name}</b>
									</td>
									<td>{order.user.email}</td>
									<td>{order.totalPrice} €</td>
									<td>{order.isPaid ? <strong className="alert alert-success border border-success rounded-pill py-1 px-2 fs-7">Pagado el {moment(order.paidAt).format('MMM Do YY')}</strong> : <strong className="alert alert-danger border border-danger rounded-pill py-1 px-2 fs-7">No pagado</strong>}</td>
									<td>{moment(order.createdAt).format('MMM Do YY')}</td>
									<td>{order.isDelivered ? <span className="badge text-bg-success">Enviado</span> : <span className="badge text-bg-dark">No enviado</span>}</td>
									<td className="d-flex justify-content-end align-item-center">
										<Link to={`/order/${order._id}`} className="text-success">
											<i className="fas fa-eye"></i>
										</Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

export default LatestOrder;
