import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteProduct } from "../../Redux/Actions/ProductActions";

const Product = (props) => {
  const { product } = props;
  const dispatch = useDispatch();

  const deletehandler = (id) => {
    if (window.confirm("Are you sure ?")) {
      dispatch(deleteProduct(id) as any);
    }
  };

  return (
		<>
			<div className="col-md-6 col-sm-6 col-lg-3 mb-5">
				<div className="card">
					<img src={product.image} alt="Product" className="card-img-top" />
					<div className="card-body">
						<div className="d-flex flex-column justify-content-between align-content-between" style={{ minHeight: '140px' }}>
							<div className="d-flex justify-content-between px-2 mb-2">
								<span className="px-2">{product.name}</span>
								<strong>{product.price}€</strong>
							</div>
							<div className="d-flex flex-column flex-sm-row justify-content-between gap-2">
								<Link to={`/product/${product._id}/edit`} className="btn btn-outline-success w-100">
									<i className="fas fa-pen"></i>
								</Link>
								<Link to="#" onClick={() => deletehandler(product._id)} className="btn btn-outline-danger w-100">
									<i className="fas fa-trash-alt"></i>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Product;
