import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import OrderDetailmain from '../components/Orders/OrderDetailmain';

const OrderDetailScreen = () => {
	const { id } = useParams();
	return (
		<>
			<Header />
			<OrderDetailmain orderId={id} />
		</>
	);
};

export default OrderDetailScreen;
