import axios from 'axios';
import { ORDER_DELIVERED_FAIL, ORDER_DELIVERED_REQUEST, ORDER_DELIVERED_SUCCESS, ORDER_DETAILS_FAIL, ORDER_DETAILS_REQUEST, ORDER_DETAILS_SUCCESS, ORDER_LIST_FAIL, ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS } from '../Constants/OrderContants';
import { logout } from './userActions';
import { URL } from '../Url';

export const listOrders = () => async (dispatch, getState) => {
	try {
		dispatch({ type: ORDER_LIST_REQUEST });

		const {
			userLogin: { userInfo }
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`
			}
		};

		const { data } = await axios.get(`${URL}/api/orders/all`, config);

		dispatch({ type: ORDER_LIST_SUCCESS, payload: data });
	} catch (error: any) {
		const message = error.message && error.response.data.message ? error.response.data.message : error.message;
		if (message === 'Not authorized, token failed') {
			dispatch(logout() as any);
		}
		dispatch({
			type: ORDER_LIST_FAIL,
			payload: message
		});
	}
};

// ORDER DETAILS
export const getOrderDetails = (id) => async (dispatch, getState) => {
	dispatch({ type: ORDER_DETAILS_REQUEST });

	const {
		userLogin: { userInfo }
	} = getState();

	const config = {
		headers: {
			Authorization: `Bearer ${userInfo.token}`
		}
	};

	try {
		const { data } = await axios.get(`${URL}/api/orders/${id}`, config);
		dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
	} catch (error: any) {
		const message = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message;
		dispatch({
			type: ORDER_DETAILS_FAIL,
			payload: message
		});
	}
};

// ORDER DELIVER
export const deliverOrder = (order) => async (dispatch, getState) => {
	try {
		dispatch({ type: ORDER_DELIVERED_REQUEST });

		const {
			userLogin: { userInfo }
		} = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`
			}
		};

		const { data } = await axios.put(`${URL}/api/orders/${order._id}/delivered`, {}, config);

		// Aquí estás despachando la acción ORDER_DELIVERED_SUCCESS con el payload 'data'
		// 'data' debería ser el objeto de la orden actualizado que recibiste de la API
		dispatch({ type: ORDER_DELIVERED_SUCCESS, payload: data });
	} catch (error: any) {
		const message = error.message && error.response.data.message ? error.response.data.message : error.message;
		if (message === 'Not authorized, token failed') {
			dispatch(logout() as any);
		}
		dispatch({
			type: ORDER_DELIVERED_FAIL,
			payload: message
		});
	}
};
