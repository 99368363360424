import React from "react";
import Header from "../components/Header";
import UserComponent from "../components/Users/UserComponent";

const UsersScreen = () => {
  return (
    <>
      <Header />
      <UserComponent />
    </>
  );
};

export default UsersScreen;
