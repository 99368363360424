import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteCategory } from "../../Redux/Actions/CategoryActions";

const CategoriesTable = (props) => {
  const { categories } = props;
  const dispatch = useDispatch();
  const deletehandler = (id) => {
    if (window.confirm("Are you sure ?")) {
      dispatch(deleteCategory(id) as any);
    }
  };

  const productCategories = categories.filter(category => category.type === 'Producto');
  const leagueCategories = categories.filter(category => category.type === 'Liga');

  const renderTable = (categories) => (
    <table className="table">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Imagen</th>
          <th>Descripción</th>
          <th className="text-end">Acción</th>
        </tr>
      </thead>
      {/* Table Data */}
      <tbody>
        {categories.map((category) => (
          <tr key={category._id}>
            <td>
              <b>{category.name}</b>
            </td>
              <td>
              <img src={category.image} alt={category.name} style={{ width: '50px', height: '50px' }} />
              </td>
            <td>{category.description}</td>
            <td>
              <Link
                to="#"
                onClick={() => deletehandler(category._id)}
                className="btn btn-sm btn-outline-danger p-2 pb-3 col-md-6"
              >
                <i className="fas fa-trash-alt"></i>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="col-md-12 col-lg-8">
      <h2>Productos</h2>
      {renderTable(productCategories)}
      <h2>Ligas</h2>
      {renderTable(leagueCategories)}
    </div>
  );
};

export default CategoriesTable;