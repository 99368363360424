import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../LoadingError/Loading';
import Message from '../LoadingError/Error';
import { listUser } from '../../Redux/Actions/userActions';

const UserComponent = () => {
	const dispatch = useDispatch();

	const userList = useSelector((state: any) => state.userList);
	const { loading, error, users } = userList;

	useEffect(() => {
		dispatch(listUser() as any);
	}, [dispatch]);
	return (
		<section className="content-main">
			<div className="content-header">
				<h2 className="content-title">Clientes</h2>
				<div>
					<Link to="#" className="btn btn-primary">
						<i className="material-icons md-plus"></i> Create new
					</Link>
				</div>
			</div>

			<div className="card mb-4">
				<header className="card-header">
					<div className="row gx-3">
						<div className="col-lg-4 col-md-6 me-auto">
							<input type="text" placeholder="Buscar..." className="form-control" />
						</div>
						<div className="col-lg-2 col-6 col-md-3">
							<select className="form-select">
								<option>Show 20</option>
								<option>Show 30</option>
								<option>Show 40</option>
								<option>Show all</option>
							</select>
						</div>
						<div className="col-lg-2 col-6 col-md-3">
							<select className="form-select">
								<option>Status: all</option>
								<option>Active only</option>
								<option>Disabled</option>
							</select>
						</div>
					</div>
				</header>

				{/* Card */}
				<div className="card-body">
					{loading ? (
						<Loading />
					) : error ? (
						<Message variant="alert-danger">{error}</Message>
					) : (
						<div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4">
							{users.map((user) => (
								<div className="col" key={user._id}>
									<div className="card card-user shadow-sm">
										<div className="card-header">
											<img className="img-md img-avatar" src="images/user.webp" alt="User pic" />
										</div>
										<div className="card-body">
											<h5 className="card-title mt-5">{user.name}</h5>
											<div className="card-text text-muted">
												{user.isAdmin === true ? <span className="badge text-bg-warning">Admin</span> : <span className="badge text-bg-info">User</span>}
												<p>
													<a href={`mailto:${user.email}`}>{user.email}</a>
												</p>
											</div>
											<button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#modal${user._id}`}>
												Aura
											</button>

											<div className="modal fade" id={`modal${user._id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
												<div className="modal-dialog">
													<div className="modal-content">
														<div className="modal-header">
															<h1 className="modal-title fs-5" id="exampleModalLabel">
																Preguntas/Respuestas
															</h1>
															<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
														</div>
														<div className="modal-body">
															{user.aura && user.aura.length > 0 ? (
																user.aura.map((aura) => (
																	<div key={aura._id}>
																		<p>
																			<strong>Pregunta:</strong> {aura.question}
																		</p>
																		<p>
																			<strong>Respuesta:</strong> {aura.answer}
																		</p>
																		<hr />
																	</div>
																))
															) : (
																<p>Este usuario aún no tiene Aura.</p>
															)}
														</div>
														<div className="modal-footer">
															<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
																Cerrar
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					)}

					{/* nav */}
					<nav className="float-end mt-4" aria-label="Page navigation">
						<ul className="pagination">
							<li className="page-item disabled">
								<Link className="page-link" to="#">
									Previous
								</Link>
							</li>
							<li className="page-item active">
								<Link className="page-link" to="#">
									1
								</Link>
							</li>
							<li className="page-item">
								<Link className="page-link" to="#">
									Next
								</Link>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</section>
	);
};

export default UserComponent;
