import React from 'react';
import Header from '../components/Header';
import AddProductMain from '../components/Products/AddProductMain';

const AddProduct = () => {
	return (
		<>
			<Header />
			<AddProductMain />
		</>
	);
};

export default AddProduct;
